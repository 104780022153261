import React, { useState } from 'react';
import './LoginPage.scss'
import { Button, Form, Input } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { getLogin } from "../../services";
import logo from '../../assest/logo.svg'
import { jwtDecode } from "jwt-decode";


const LoginComponent: React.FC<any> = () => {
    const [form] = Form.useForm();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [visible, setVisible] = useState('')
    const navigate = useNavigate();



    const loginHandler = async (e: any) => {
        e.preventDefault();
        const req = await getLogin({
            "username": username,
            "password": password
        })

        if (req.status === 200) {
            setError(req.data.message)
            setVisible(req.data.success)

            if (req.data.success) {
                navigate("/home");
                console.log('success')
                const decoded: any = jwtDecode(req.data.result.token);
                localStorage.setItem("role", decoded?.roles[0]?.authority)
                localStorage.setItem("auth_token", req.data.result.token)

                localStorage.setItem("refreshToken", req.data.result.refreshtoken)
                localStorage.setItem('userName', username)


            }
        } else if (req.status === 503) {
            window.location.reload();
        }
    }
    return (
        <div className='login-all'>
            <div className='container-login'>
                <div className='logo-login'>
                    <img src={logo} alt='logoqrix' />
                    
                </div>
                <p style={{fontSize:24,textAlign:'center'}}>Welcome to QRIX!👋🏻</p>
                <Form
                    form={form}
                    className='LoginForm'
                >

                    <p className={'errormessage'}> {visible ? "" : error}</p>
                    <div className='loginInput1'>

                        <label className='loginLabel'>Username</label>

                        <Input

                            name='username'
                            className='loginInput'
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className='loginInput2'>
                        <label className='loginLabel'>Password</label>

                        <Input
                            name='password'
                            className='loginInput'
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <div className='loginButton'>
                        <Button


                            htmlType="submit"
                            onClick={loginHandler}
                        >Login</Button>
                    </div>


                </Form>
            </div>

        </div>
    );
};
export default LoginComponent;
