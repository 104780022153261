import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import {
  PlusOutlined,
  EditTwoTone,
  EyeInvisibleTwoTone,
} from "@ant-design/icons";
import { getAllUsers } from "../../services";

const columns = [
  {
    title: "Id",
    key: "id",
    dataIndex: "id",
  },
  {
    title: "UserName",
    key: "userName",
    dataIndex: "userName",
  },
  {
    title: "Full Name",
    key: "fullName",
    dataIndex: "fullName",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Phone Number",
    key: "phoneNumber",
    dataIndex: "phoneNumber",
  },
  {
    title: "Role",
    key: "userRole",
    dataIndex: "userRole",
  },
  {
    title: "OrganizationName",
    key: "organizationName",
    dataIndex: "organizationName",
  },
  {
    title: "Active",
    key: "active",
    dataIndex: "active",
  },
  // {
  //   title: 'Edit',
  //   key: 'edit',
  //   width: '64px',
  //   render: () => (
  //     <Button>
  //       <EditTwoTone />
  //     </Button>
  //   ),
  // },
  // {
  //   title: 'Deactivate',
  //   key: 'deactivate',
  //   width: '64px',
  //   render: () => (
  //     <Button>
  //       <EyeInvisibleTwoTone twoToneColor="red" />
  //     </Button>
  //   ),
  // },
];

const tableStyle = {
  padding: "40px",
  width: "100%",
  minWidth:'1385px',
  height: "100%",
  overflow: "auto",
};

const Users = () => {
  const [userData, setUserData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getAllUsers();

      if (response && response.success && Array.isArray(response.data)) {
        setUserData(response.data);
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Table
      style={tableStyle}
      dataSource={userData}
      columns={columns}
      rowKey="id"
    />
  );
};

export default Users;
