import { Button, Form, FormInstance, Input, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { addOrganization } from "../../services";
interface SubmitButtonProps {
  form: FormInstance;
}
const AddRestoran = ({ visible, onOk, getData }: any) => {
  const [modalvisible, setVisible] = useState(visible);
  const [form] = Form.useForm();
  const handleCancel = (args: any) => {
    setVisible(false);
    console.log(args);
    form.resetFields();
    onOk(false);
  };
  const handleOk = () => {
    setVisible(false);
    form.resetFields();
    onOk(false);
  };

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
    form,
    children,
  }) => {
    const [submittable, setSubmittable] = useState<boolean>(true);
    form.validateFields();

    return (
      <Button type="primary" htmlType="submit" disabled={!submittable}>
        {children}
      </Button>
    );
  };

  const handleCreate = async (values: any) => {
    try {
      const body = {
        ...values,
      };

      const data = await addOrganization(body);
      handleOk();
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={modalvisible}
      title={"Add Organization"}
      footer={null}
      onOk={handleOk}
      onCancel={handleOk}
    >
      <Form form={form} onFinish={handleCreate}>
        <Form.Item label={"erpLogin"} name={"erpLogin"}>
          <Input />
        </Form.Item>
        <Form.Item label={"paymentLogin"} name={"paymentLogin"}>
          <Input />
        </Form.Item>
        <Form.Item label={"menuUrl"} name={"menuUrl"}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Space>
            <SubmitButton form={form}>Create</SubmitButton>
            <Button onClick={handleCancel} htmlType="reset">
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddRestoran;
