import React, { useEffect, useState } from "react";
import './layout.scss';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import logo from "../../assest/logo.svg";
import Transfers from "../Home/Transfers";
import Restaurants from "../Home/Restaurants";
import Users from "../Home/Users";
import LoginComponent from "../Login/LoginPage";
import Tables from "../Home/Tables";
import ErpSystem from "../Home/ErpSystem";
import { IoRestaurant } from "react-icons/io5";
import { MdTableBar } from "react-icons/md";
import { IoCard } from "react-icons/io5";
import useResponsiveWidth from "../../hooks/useResponsiveWidth";

const { Header, Sider, Content } = Layout;

const LayoutPage: React.FC = (props: any) => {
  const role: any = localStorage.getItem("role");
  const [collapsed, setCollapsed] = useState(false);
  const [openLabel, setOpenLabel] = useState<any>('');
  const navigate = useNavigate();
  const location = useLocation();
  const responsiveWidth : any = useResponsiveWidth() 
  const menuItems = [
    {
      key: "transfers",
      icon: <IoCard />,
      label: "Əməliyyatlar",
      roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    },
    {
      key: "restaurants",
      icon: <IoRestaurant />,
      label: "Restoranlar",
      roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    },
    {
      key: "tables",
      icon: <MdTableBar />,
      label: "Masalar",
      roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    },
    {
      key: "users",
      icon: <UserOutlined />,
      label: "İstifadəçilər",
      roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    },
    // {
    //   key: "erpsystem",
    //   icon: <UserOutlined />,
    //   label: "ERP System",
    //   roles: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    // },
  ];

  //styles
  const siderStyle: React.CSSProperties = {
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "#fff",
    position: "fixed",
    height: "100vh",
    left: 0, // Stick to the left
    top: 0, // Start from the top
    zIndex: 1000,
    transition: "width 0.2s",
  };
  const layoutStyle = {
    borderRadius: 8,
    overflow: "hidden",
  };

  const contentStyle: React.CSSProperties = {
    height: "100vh",
    marginTop: "64px",
    textAlign: "center",
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "#EDF0F7",
    overflow: "auto",
    flex: 1,
    marginLeft: collapsed ? "80px" : "200px",
    minHeight: "calc(100vh - 64px)",
  };

  const headerStyle: React.CSSProperties = {
    backgroundColor: "#fff",
    textAlign: "end",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "64px",
    borderBottom: "1px solid #ddd",
    position: "fixed", // Make header fixed
    top: 0, // Stick to the top
    left: collapsed ? "80px" : "200px", // Adjust based on collapsed sidebar
    right: 0, // Full width across the right side
    zIndex: 1000, // Ensure header stays above other content
  };

  const routes = [
    {
      component: <Transfers />,
      path: "transfers",
    },
    {
      component: <Restaurants />,
      path: "restaurants",
    },
    {
      component: <Tables />,
      path: "tables",
    },
    {
      component: <Users />,
      path: "users",
    },
    {
      component: <ErpSystem />,
      path: "erpsystem",
    },
  ];
    



  const selectNav = (e: any) => {

    setOpenLabel(menuItems.find((item) => item.key === e.key)?.label);
    navigate(e.key);
  };

  const handleLogOut = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");

    navigate("/login");
  };

  const filteredMenuItems = menuItems.filter((item) => {
    return !item.roles || item.roles.includes(role);
  });

  console.log(location.pathname);
  
  useEffect(() => { 
    if(responsiveWidth <= 768 ){
      setCollapsed(true)
    }else{
      setCollapsed(false)
    }
  },[responsiveWidth])


  return (
    <Layout style={layoutStyle}>
      <Sider style={siderStyle} collapsed={collapsed}>
        <Header
          style={{
            backgroundColor:"#fff",
            textAlign: "start",
            display: "flex",
            color: "#B1B1B1",
            alignItems: "center",
            position: "relative", // Keep this header relative to Sider
            height: "64px",
            padding: collapsed ? "0 23px" : "0 50px",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              color: "#B1B1B1",
            }}
          />
          {!collapsed ? (
            <img
              onClick={() => {
                navigate("/home");
                setOpenLabel('');
              }}
              src={logo}
              alt="logo"
            />
          ) : (
            ""
          )}
        </Header>
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.split('/home')[1].substring(1)]}
          style={{backgroundColor:"#fff",marginTop:18}}          
          items={filteredMenuItems} 
          onSelect={(e) => selectNav(e)}
        />
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <div>
            <h1 style={{color:"#2D60FF"}}>{openLabel}</h1>
          </div>
          <div>
          <Button
            type="text"
            className="header-right-button"
            icon={<UserOutlined />}
            
          />
          <Button
            type="text"
            className="header-right-button"
            onClick={handleLogOut}
            icon={<LogoutOutlined />}
            
          />
          </div>
          
        </Header>
        <Content style={contentStyle}>
          {location.pathname === "/home" ? <h1 style={{color:"#000"}}>Welcome!</h1> : ""}
          <Routes>
            {routes.map((route: any) => (
              <Route
                element={route.component}
                path={route.path}
                key={route.path}
              />
            ))}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};
export default LayoutPage;
