import React from 'react';
import { Outlet, Routes, Route, Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginComponent from './components/Login/LoginPage';
import LayoutPage from './components/layout/layout';
import Transfers from './components/Home/Transfers';
import Restaurants from './components/Home/Restaurants';
import Users from './components/Home/Users';
import Tables from './components/Home/Tables';
import ErpSystem from './components/Home/ErpSystem';
import PrivateRoute from './services/PrivateRoute';


const App: React.FC = () => {
  

  const router = createBrowserRouter([
    {
      path: '/home/*',
      element: <PrivateRoute  />, 
      children: [
        {
          path: '',
          element: <LayoutPage />,
          children: [
            { path: 'transfers', element: <Transfers /> },
            { path: 'restaurants', element: <Restaurants /> },
            { path: 'tables', element: <Tables /> },
            { path: 'users', element: <Users /> },
            { path: 'erpsystem', element: <ErpSystem /> },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: <LoginComponent  />, 
    },
    {
      path: '/',
      element: <Navigate to="/login" replace />,
    },
  ]);
  return (
      <RouterProvider router={router} />
  );
};

export default App;
