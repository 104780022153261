import { useEffect, useState } from "react";

const useResponsiveWidth = () => {
  
  const [responsiveWidth, setResponsiveWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    function handleResize() {
      setResponsiveWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [responsiveWidth]);

  return responsiveWidth;
}

export default useResponsiveWidth;