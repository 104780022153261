import { render } from "@testing-library/react";
import { Button, Form, GetRef, Modal, Space, Table } from "antd";
import { EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./restoran.css";
import { getAllOrganizations, updateOrganizations } from "../../services";
import Input, { InputRef } from "antd/es/input/Input";
import FullScreenLoading from "../FullPageLoading/fullpageLoading";
import DownloadImage from "../DownLoadImage/DownloadImage";
import AddRestoran from "../ModalAddRest/AddRestoran";
import { useForm } from "antd/es/form/Form";
import { Link } from "react-router-dom";

const data: any[] = [
  {
    key: "1",
    name: "John Brown",
    date: 32,
  },
];
const tableStyle = {
  padding: "40px",
  minWidth:'auto',
};
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

const Restaurants = () => {
  //States
  const role: any = localStorage.getItem("role");
  const [organizations, setOrganizations] = useState([]);
  const [row, setRow] = useState<any>({});
  const [pageLoading, setPageLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  //Column
  const columns: any = [
    {
      title: "Name",
      dataIndex: "restaurant_name",
      editable: false,
    },
    {
      title: "Menu Url",
      dataIndex: "menuUrl",
      editable: false,
      render:(text:any,record:any)=>{
       return <Link to={text} target="_blank">{text}</Link>
          
      }
    },
    {
      title: "Edit Menu Url",
      key: "edit",
      width: "20%",
      hidden: role !== "ROLE_SUPER_ADMIN",
      render: (text: any, record: any) => (
        <Button
          onClick={() => {
            setModalVisible(true);
            setRow(record);
            form.setFieldsValue({
              "menuUrl":record.menuUrl
            })
          }}
        >
          <EditTwoTone />
        </Button>
      ),
    },
  ];

  const defaultColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const fetchData = async () => {
    try {
      let temp_array: any = [];
      const data: any = await getAllOrganizations();

      data?.data?.map((row: any) => {
        temp_array.push({
          key: row.organizationId,
          restaurant_id: row.organizationId,
          restaurant_name: row.organizationName,
          menuUrl: row.menuUrl,
          editable: false,
        });
      });
      setOrganizations(temp_array);
    } catch {}
  };


  const handleSave = async () => {
    setPageLoading(true);
    console.log(form.getFieldValue("menuUrl"));

    const newOrganizations = await updateOrganizations(
      row?.restaurant_id,
      form.getFieldValue("menuUrl")
    );
    if (newOrganizations.success) {
      fetchData();
      setModalVisible(false);
      form.resetFields();
    }
    setPageLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <div>
      <div className="add-button-rest">
       {
        role === "ROLE_SUPER_ADMIN" ? <Button
        onClick={() => setVisible(true)}
        type="dashed"
        style={{ backgroundColor: "green", color: "#FFF" }}
      >
        <PlusOutlined color="black" />
        Add
      </Button>:''
       }
        
      </div>
      <Table
        components={components}
        style={tableStyle}
        rowClassName={() => "editable-row"}
        dataSource={organizations}
        columns={defaultColumns}
      />
      {modalVisible && (
        <Modal
          open={modalVisible}
          title={"Edit Organization"}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Form form={form}>
            <Form.Item label={"Menu Url"} name="menuUrl">
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      <AddRestoran
        visible={visible}
        onOk={(e: any) => setVisible(e)}
        getData={() => fetchData()}
      />
      {pageLoading ? <FullScreenLoading /> : ""}
    </div>
  );
};

export default Restaurants;
