import { render } from "@testing-library/react";
import { Button, Form, GetRef, Select, Space, Table } from "antd";
import { DownloadOutlined, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import JSZip from 'jszip';
import "./restoran.css";
import '../DownLoadImage/qrCode.css'
import {
  getAllOrganizations,
  getAllTables,
  updateOrganizations,
} from "../../services";
import Input, { InputRef } from "antd/es/input/Input";
import FullScreenLoading from "../FullPageLoading/fullpageLoading";
import DownloadImage from "../DownLoadImage/DownloadImage";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
import QRCodeStyling from "qr-code-styling";
import qrlogo from "../../assest/qrlogo.svg";
import logoqrix from "../../assest/logoqrix.svg";
import fingericon from "../../assest/fingericon.svg";
import html2canvas from "html2canvas";

const data: any[] = [
  {
    key: "1",
    name: "John Brown",
    date: 32,
  },
];
const tableStyle = {
  padding: "20px",
};
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true, message: `${title} is required.` }]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

const Tables = () => {
  //States
  const [organizations, setOrganizations] = useState([]);
  const [tables, setTables] = useState([]);
  const ref: any = useRef();
  const elementRef: any = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [activeOrganization, setActiveOrganization] = useState<any>(null);

  //Column
  const columns: any = [
    // {
    //   title: "Table Name",
    //   dataIndex: "tableName",
    //   width: "20%",

    // },
    {
      title: "Section Name",
      dataIndex: "sectionName",
      width: "20%",
    },
    {
      title: "Number",
      dataIndex: "tableNumber",
      width: "20%",
    },
    {
      title: "QR",
      dataIndex: "qrCode",
      width: "2%",
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              height: "40px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",

              gap: 10,
            }}
          >
            <DownloadImage
              setIsLoading={setPageLoading}
              base64String={text}
              fileName={record}
            />
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    try {
      setPageLoading(true);
      let temp_array: any = [];
      let rest_array: any = [];
      const data: any = await getAllOrganizations();

      let tableData = [];

      tableData = await getAllTables(data.data[0].organizationId);

      tableData?.result.tableList.map((row: any) => {
        temp_array.push({
          key: row.tableId,
          table_id: row.tableId,
          tableNumber: row.tableNumber,
          tableName: row.tableName,
          sectionName: row.sectionName,
          qrCode: row.urlForQr,
          editable: false,
        });
      });
      data?.data?.map((row: any) => {
        rest_array.push({
          value: row.organizationId,
          label: row.organizationName,
        });
      });
      setOrganizations(rest_array);
      setTables(temp_array);
      setPageLoading(false);
    } catch {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleChangeRestoran = async (e: any) => {
    console.log(e, 'e');
    setPageLoading(true);
    const temp_array: any = [];
    const tableData = await getAllTables(e);
    tableData?.result.tableList.map((row: any) => {
      temp_array.push({
        key: row.tableId,
        table_id: row.tableId,
        tableName: row.tableName,
        tableNumber: row.tableNumber,
        sectionName: row.sectionName,
        qrCode: row.urlForQr,
        editable: false,
      });
    });
    setTables(temp_array);
    setPageLoading(false);
  };

  const qrCode = new QRCodeStyling({
    width: 540,
    height: 540,
    image: qrlogo,

    dotsOptions: {
      color: "#4267b2",
      type: "extra-rounded",
      gradient: {
        type: "linear",
        rotation: 1.5707963267948966,
        colorStops: [
          { offset: 0, color: "#EA3B24" },
          { offset: 1, color: "#2473EA" },
        ],
      },
    },
    imageOptions: {

      imageSize: 0.5,
      crossOrigin: "anonymous",
    },
  });

  console.log(tables);

  const handleDownloadAll = async (tables: any[]) => {
    let styleElement;
    try {
      setPageLoading(true);
      styleElement = document.createElement('style');
      styleElement.textContent = 'body { overflow: hidden; }';
      document.head.appendChild(styleElement);
      const zip = new JSZip();
      const folder = zip.folder(tables[0]?.sectionName.toUpperCase());
      

      for (let i = 0; i < tables.length; i++) {
        const tableName = tables[i]?.tableName;
        const tableData = tables[i]?.qrCode;
        
        if (typeof tableName !== 'string' || !tableName.trim()) {
          continue;
        }

        const canvas = await generateJPEG(tableName, tableData);

        if (canvas) {
          const dataUrl = canvas.toDataURL("image/jpeg", 1.0);
          const fileData = dataUrl.split(',')[1];

          folder?.file(`${tables[i]?.sectionName.toUpperCase() + "_" + tables[i]?.tableNumber
        }.jpeg`, fileData, { base64: true });
        }
      }

      zip.generateAsync({ type: 'blob' }).then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'qr_codes.zip';
        link.click();
      });
    } catch (error) {

    } finally {
      setPageLoading(false);
      if (styleElement && styleElement.parentNode) {
        styleElement.parentNode.removeChild(styleElement);
      }
    }
  };

  const generateJPEG = async (tableName: string, tableData: string) => {
    if (elementRef.current === null) {
      return null;
    }

    setBase64String(tableData);

    await new Promise((resolve) => setTimeout(resolve, 100));

    const qrElement = elementRef.current;
    qrElement.style.display = "block";

    const canvas = await html2canvas(qrElement, {
      width: 840,
      height: 1270,
      useCORS: true,
      scale: 2,
    });

    qrElement.style.display = "none";
    return canvas;
  };


  useEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = "";
      qrCode.append(ref.current);
      elementRef.current.style.display = "none";
    }
  }, [qrCode]);

  useEffect(() => {
    if (qrCode && base64String) {
      qrCode.update({
        data: base64String,
      });
    }
  }, [base64String, qrCode]);


  return (
    <div>
      <div
      // style={{ display: "flex", justifyContent: "center", gap: 10 }}
      >
        <Select
        style={{ width: 250, marginRight: 10 }}
          placeholder={"Restoran adı"}
          options={organizations}
          defaultValue={organizations[1]}
          onChange={handleChangeRestoran}
        />
        <Button style={{ marginLeft: 20 , backgroundColor: "#c88100", color: 'white'}} onClick={() => {
          handleDownloadAll(tables);
        }}>Download all QRs <DownloadOutlined /></Button>

      </div>
      <Table
        components={components}
        style={tableStyle}
        rowClassName={() => "editable-row"}
        dataSource={tables}
        columns={columns}
      />
      <div className="allQrs"
        ref={elementRef}
        style={{
          textAlign: "center",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          width: 840,
          // height: 1190,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          // visibility: "hidden",
        }}
      >
        <img src={logoqrix} alt="logo" style={{ margin: "28px 0px 0px 24px", width: '148px' }} />
        <div>
          <p style={{ fontWeight: 600, fontSize: 36, lineHeight: "26px", color: 'black', marginBottom: 35 }}>
            Scan for Menu & Payment
          </p>
          <p style={{ fontWeight: 600, fontSize: 36, lineHeight: "26px", marginBottom: "34px", color: 'black' }}>
            Menyu & Ödəniş üçün skan et
          </p>
        </div>
        <div style={{ margin: '40px auto', width: '75px', height: '75px' }} >
          <img
            className="fingerIcon"
            alt='pointer'
            src={fingericon}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div ref={ref} style={{ marginBottom: "0px" }} />
        <p style={{
          fontSize: "32px",
          marginBottom: "40px",
          color: 'black',
        }}>
          Bon Appétit & Easy Pay <span style={{ fontSize: "40px" }}>🤩</span>
        </p>
      </div>
      {pageLoading ? <FullScreenLoading /> : ""}
    </div>
  );
};

export default Tables;
