import axios from "axios";

// export const API_URL = "http://65.108.90.115:7770/iiko-integration/api/v1";
export const API_URL = "https://admin.qrix.az/iiko-integration/api/v1"; // /build

export const http = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  },
  withCredentials: false,
});

export const getLogin = async (request: any) => {
  const data = await axios.post<any>(`${API_URL}/login`, request);
  return data;
};

export const getAllOrders = async (
  username: any,
  fromDate: any,
  toDate: any
): Promise<string> => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );

  const { data } = await http.get<any>(
    `${API_URL}/get_all_orders?fromDate=${fromDate}&toDate=${toDate}`
  );
  return data;
};

export const getAllOrganizations = async () => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.get<any>(`get_all_organizations`);
  return data;
};

export const getAllUsers = async () => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.get<any>(`/users`);
  return data;
};

export const getAllTables = async (organizationId: string) => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.get<any>(`get_table_list/${organizationId}`);
  return data;
};

export const updateOrganizations = async (organizationId: any, req: any) => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );

  const request = {
    menuUrl: req,
  };

  const { data } = await http.put<any>(
    `update_organization/${organizationId}`,
    request
  );
  return data;
};

export const refundAmount = async (key: any, refundAmount: any) => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.get<any>(
    `refund_amount?payment-id=${key}&refund-amount=${refundAmount}`
  );
  return data;
};

export const retryAmount = async (key: any, orderId: any) => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.get<any>(
    `retry_amount?payment-id=${key}&order-id=${orderId}`
  );
  return data;
};

export const addOrganization = async (req: any) => {
  const http = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    },
    withCredentials: false,
  });
  http.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        return { authorized: false };
      }
      return { authorized: false };
      // return Promise.reject(error)
    }
  );
  const { data } = await http.post<any>(`add_organization`, req);
  return data;
};
