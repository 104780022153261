import { AutoComplete, Button, Input, Popconfirm, Popover, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { getAllOrders, refundAmount, retryAmount } from "../../services";
import moment from "moment";
import { Excel } from 'antd-table-saveas-excel';
import './index.scss'
import { useNavigate } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import FullScreenLoading from "../FullPageLoading/fullpageLoading";
import { SiMicrosoftexcel } from "react-icons/si";

import { color, minWidth, width } from "@mui/system";
import { boxShadow } from "html2canvas/dist/types/css/property-descriptors/box-shadow";
const Transfers = () => {
  const navigate = useNavigate();
  const [transferDate, setTransferDate] = useState(
    moment().local().format("YYYY-MM-DD")
  );
  const [originalTableData, setOriginalTableData] = useState([]);
  const [valueAmountRefund, setvalueAmountRefund] = useState(null);
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
  const [loading, setLoading] = useState(false)
  const tableStyle = {
    padding: "40px",
    minWidth: '1780px',
    color: "white",
    boxShadow: ""
  };
  const contentStyle: React.CSSProperties = {
    textAlign: "center",
    width: "100%",
    height: "80vh",
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "#0958d9",
  };
  const content = (record: any) => (
    <div>
      <Input onChange={(e: any) => setvalueAmountRefund(e.target.value)} />

      <Button onClick={() => handleRefund(record.paymentGuid)} type="primary">
        Okay
      </Button>
    </div>
  );
  const columns: any = [
    {
      title: "Organization Name",
      dataIndex: "organizationName",
      width: 140
    },
    {
      title: "Section Name",
      dataIndex: "sectionName",
    },
    {
      title: "Table Number",
      dataIndex: "tableNumber",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Service Fee",
      dataIndex: "serviceFee",
    },
    {
      title: "Tip Amount",
      dataIndex: "tipAmount",
    },
    {
      title: "Creation Time",
      dataIndex: "creationTime",
    },
    {
      title: "Waiter Name",
      dataIndex: "waiterName",
    },
    {
      title: "Fiscal Id",
      dataIndex: "fiscalId",
    },
    {
      title: "ErpOrder Id",
      dataIndex: "erpOrderId",
    },
    {
      title: "PaymentOrder Id",
      dataIndex: "paymentOrderId",
    },
    {
      title: "Waiter Id",
      dataIndex: "waiterId",
    },
    {
      title: "Waiter Mobile Number",
      dataIndex: "waiterMobileNumber",
    },
    // {
    //   title: "operation",
    //   dataIndex: "operation",

    //   render: (_: any, record: any) => {
    //     if (record.paymentStatusId === 1) {
    //       return (
    //         <div>
    //           {/* <Popover title="Sure to Refund?">
    //             <a>Refund</a>
    //           </Popover> */}

    //           <Popconfirm
    //             title="Sure to Retry?"
    //             onConfirm={() => handleRetry(record.key, record.orderId)}
    //           >
    //             <a>Retry</a>
    //           </Popconfirm>
    //         </div>
    //       );
    //     }
    //     // else if (record.paymentStatusId === 2) {
    //     //   return (
    //     //     <div>
    //     //       <Popover content={() => content(record)} title="Refund Amount:">
    //     //         <a>Refund</a>
    //     //       </Popover>
    //     //     </div>
    //     //   );
    //     // }
    //   },
    // },
  ];

  const handleRefund = async (key: any) => {
    const data = await refundAmount(key, valueAmountRefund);
    setvalueAmountRefund(null);
  };
  const handleRetry = async (key: any, orderId: any) => {
    const data = await retryAmount(key, orderId);
  };

  const handleAllOrders = async (fromDate: any, toDate: any) => {

    let data: any;
    setLoading(true)
    try {
      data = await getAllOrders(
        localStorage.getItem("userName"),
        fromDate, toDate
      );
      console.log('data', data.data);
      setOriginalTableData(data.data);
    } catch {
      localStorage.removeItem("userName");
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("role");
      navigate("/login");
    }
    setLoading(false)

  };

  const saveAsExcel = () => {
    const newColumns = columns.slice(0, -1);
    const excel = new Excel();
    excel.rowHeight = 1.5;
    excel
      .addSheet('qrix')
      .addColumns(newColumns)
      .addDataSource(originalTableData)
      .saveAs('orders.xlsx');
  }
  const handleFromdate = (date: any, datevalue: any) => {
    console.log(date, datevalue)
    setFromDate(moment(date).format('YYYY-MM-DD'))
  }
  const handleToDate = (date: any, datevalue: any) => {
    setToDate(moment(date).format('YYYY-MM-DD'))
  }
  useEffect(() => {
    console.log(moment().format('YYYY-MM-DD'))
    handleAllOrders(fromDate, toDate);
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{display:'none'}}>11-4</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: 40 }}>
          <LocalizationProvider
            dateAdapter={AdapterMoment}
          >
            <DemoContainer sx={{ display: 'flex', alignItems: 'center', border: 'none' }} components={['DatePicker']}>

              <DatePicker
                className="date"
                sx={{ backgroundColor: 'white', height: 40, }}
                // label={"From Date:"}
                defaultValue={moment()}
                format="YYYY-MM-DD"
                onChange={(e, valueStr) => handleFromdate(e, valueStr)}
              />

              <p style={{ fontSize: 20, color: '#000' }}>-</p>

              <DatePicker
                className="date"
                format="YYYY-MM-DD"
                sx={{ backgroundColor: 'white' }}
                defaultValue={moment()}
                // label={"To Date:"}
                onChange={(e, valueStr) => handleToDate(e, valueStr)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button loading={loading} style={{marginTop:5, marginLeft: 20, backgroundColor: "#4169df", color: "white",textAlign:"center",height:35,width:105 }} onClick={() => handleAllOrders(fromDate, toDate)}>Axtar</Button>
        </div>


      </div>

      <div style={{display:"flex",alignItems:"center",justifyContent:'start'}}>
        <Button style={{ marginLeft: 40, backgroundColor: "#c88100", color: "white", height: 40 }}
          onClick={saveAsExcel}
        ><SiMicrosoftexcel style={{ fontSize: 20 }} /> Save as Excel</Button>
      </div>

      <Table
        className="table"
        style={tableStyle}
        dataSource={originalTableData}
        columns={columns}
        locale={{ emptyText: "Bu tarixdə məlumat yoxdur" }}
        rowClassName={(record: any) => (record.paymentStatusId === 1 ? 'red-row' : '')}
      />
      {loading ? <FullScreenLoading /> : ""}
    </div>
  );
};

export default Transfers;
