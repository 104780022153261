import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import QRCodeStyling from "qr-code-styling";
import React, { useEffect, useRef, useState } from "react";
import qrlogo from "../../assest/qrlogo.svg";
import logoqrix from "../../assest/logoqrix.svg";
import fingericon from "../../assest/fingericon.svg";
import html2canvas from "html2canvas";
import './qrCode.css'
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface DownloadImageProps {
  base64String: string;
  fileName?: any;
  setIsLoading: any;
}

const DownloadImage: React.FC<DownloadImageProps> = ({
  base64String,
  fileName,
  setIsLoading,
}) => {
  const ref: any = useRef();
  const elementRef: any = useRef(null);

  const qrCode = new QRCodeStyling({
    width: 540,
    height: 540,
    image: qrlogo,

    dotsOptions: {
      color: "#4267b2",
      type: "extra-rounded",
      gradient: {
        type: "linear",
        rotation: 1.5707963267948966,
        colorStops: [
          { offset: 0, color: "#EA3B24" },
          { offset: 1, color: "#2473EA" },
        ],
      },
    },
    imageOptions: {

      imageSize: 0.5,
      crossOrigin: "anonymous",
    },
  });

  const handleDownload = () => {
    if (elementRef.current === null) {
      return;
    }
    setIsLoading(true);
    const qrElement = elementRef.current;
    qrElement.style.display = "block";

    html2canvas(elementRef.current, {
      width: 840,
      height: 1190,
      useCORS: true,
      scale: 2,
    }).then((canvas) => {
      const dataUrl = canvas.toDataURL("image/jpeg", 1.0);

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${fileName?.sectionName.toUpperCase() + "_" + fileName?.tableNumber
        }.jpeg`;
      link.click();
      qrElement.style.display = "none";
      setIsLoading(false);
    });
  };

  useEffect(() => {
    ref.current.innerHTML = "";
    qrCode.append(ref.current);
    elementRef.current.style.display = "none";
  }, [base64String]);

  useEffect(() => {
    qrCode.update({
      data: base64String,
    });
  }, [base64String]);

  return (
    <div
      // className="flex"
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
      }}

    >
      <div>
        <Button onClick={handleDownload}>
          <DownloadOutlined />
        </Button>
      </div>
      <div
        className="qrStyle"
        ref={elementRef}
        style={{
          textAlign: "center",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          width: 840,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        <img src={logoqrix} alt="logo" style={{ margin: "28px 0px 0px 24px", width: '148px' }} />
        <div>
          <p style={{ fontWeight: 600, fontSize: 36, lineHeight: "26px", }}>
            Scan for Menu & Payment
          </p>
          <p style={{ fontWeight: 600, fontSize: 36, lineHeight: "26px", marginBottom: "34px" }}>
            Menyu & Ödəniş üçün skan et
          </p>
        </div>
        <div style={{ margin: '30px auto', width: '75px', height: '75px' }} >
          <img
            className="fingerIcon"
            alt='pointer'
            src={fingericon}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div ref={ref} style={{ marginBottom: "50px" }} />
        <p style={{
          fontSize: "32px",
          marginBottom: "40px"
        }}>
          Bon Appétit & Easy Pay <span style={{fontSize: "40px"}}>🤩</span>
        </p>
      </div>
    </div>
  );
};

export default DownloadImage;
