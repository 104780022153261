import { render } from '@testing-library/react';
import { Button, Form, GetRef, Select, Space, Table } from 'antd'
import {EditTwoTone, EyeInvisibleTwoTone, PlusOutlined} from '@ant-design/icons'
import { Content } from 'antd/es/layout/layout'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './restoran.css'
import { getAllOrganizations, getAllTables, updateOrganizations } from '../../services';
import Input, { InputRef } from 'antd/es/input/Input';
import FullScreenLoading from '../FullPageLoading/fullpageLoading';
import DownloadImage from '../DownLoadImage/DownloadImage';

const data: any[] = [
    {
        key: '1',
        name: 'John Brown',
        date: 32,

    },


];
const tableStyle = {
    padding: '40px',

}
type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                                title,
                                                                                editable,
                                                                                children,
                                                                                dataIndex,
                                                                                record,
                                                                                handleSave,
                                                                                ...restProps
                                                                            }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[{ required: true, message: `${title} is required.` }]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingInlineEnd: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    name: string;
    age: string;
    address: string;
}

const ErpSystem = () => {
    //States
    const [organizations, setOrganizations] = useState([])
    const [tables, setTables] = useState([])
    const [pageLoading,setPageLoading] = useState(false)


     //Column
    const columns: any = [
        {
            title: 'Name',
            dataIndex: 'tableNumber',
            //   editable:true
        },
        {
            title: 'Edit',
            key: 'edit',
            width: '64px',
            render: () => (
                <Button>
                    <EditTwoTone />
                </Button>
            ),
        },
        {
            title: 'Deactivate',
            key: 'deactivate',
            width: '64px',
            render: () => (
                <Button>
                    <EyeInvisibleTwoTone twoToneColor="red" />
                </Button>
            ),
        }


    ];

    const defaultColumns = columns.map((col:any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: any) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });
    const fetchData = async () => {

        try {
            let temp_array: any = []
            let rest_array: any = []
            const data: any = await getAllOrganizations()
            
            let tableData=[]
            if(data.data.length === 1){
                
                tableData = await getAllTables(data.data[0].organizationId)
            }
            tableData?.result.tableList.map((row: any) => {
                temp_array.push({
                    key:row.tableId,
                    table_id: row.tableId,
                    tableNumber:row.tableNumber,
                    //   qrCode:base64,
                    qrCode:row.base64Qr,
                    editable:false
                })
            })
            data?.data?.map((row: any) => {
                rest_array.push({

                    value: row.organizationId,
                    label:row.organizationName,

                })
            })
            setOrganizations(rest_array)
            setTables(temp_array)
        }catch {

        }

    }
    const handleEdit = (restaurant_id:any) =>{
        let temp_array:any = organizations
        const findIndex:any = organizations.find((c:any)=> c.restaurant_id === restaurant_id)
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        temp_array[findIndex]?.editable === true
        setOrganizations(temp_array)

    }

    const handleSave = async(row:any)=>{
        setPageLoading(true)
        const newData:any = [...organizations];
        const index = newData.findIndex((item:any) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        // setOrganizations(newData);
        const newOrganizations = await updateOrganizations(row.key,row.restaurant_name)
        if(newOrganizations.success)
        {fetchData()}
        setPageLoading(false)
    }
    // useEffect(() => {
    //     fetchData()
    // },[])

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };



    return (
        <div>
            <div className='add-button-rest'>
                <Button type="dashed" style={{backgroundColor:'green',color:"#FFF"}}>
                    <PlusOutlined color='black' />
                    Add
                </Button>
            </div>
            <Table
                components = {components}
                style={tableStyle}
                rowClassName={() => 'editable-row'}
                dataSource={tables}
                columns={defaultColumns}
            />
            {pageLoading ? <FullScreenLoading/> :''}
        </div>

    )
}

export default ErpSystem